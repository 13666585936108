// import React, {useState} from 'react';
// import { Link } from 'react-scroll';
// import './NavbarStyles.css'


// import { MenuIcon, XIcon } from '@heroicons/react/outline';

// const Navbar = () => {
//     const [nav, setNav] = useState(false)
//     const handleClick = () => setNav(!nav)

//     const handleClose =()=> setNav(!nav)

//   return (
//     <div className='w-relative h-[80px] z-10 bg-white  '>
//       <div className=' grid md:grid-cols-2 max-w-[1240px] items-center m-auto '>
//         {/* <div className='items-center flex justify-between'> */}
//           <img src={Logo} alt="Logo" className="w-36" />
//           <div className="justify-end space-x-4 space-x-reverse">

//         {/* Menu */}
//           <ul className='lien hidden md:flex justify-end'>
          // <button className='border-none bg-transparent text-black mr-4'>
          // <Link to="home" smooth={true} duration={500}>Présentation</Link>
          // </button>
          // <button className='border-none bg-transparent text-black mr-4'>
          // <Link to="application" smooth={true} offset={-100} duration={800}>Application B-FLOW</Link>
          // </button>
          // <button className='border-none bg-transparent text-black mr-4'>
          // <Link to="contact" smooth={true} offset={-50} duration={1000}>Contact</Link>
          // </button>
//           </ul>

//           </div>
//         {/* </div> */}

//         {/* Hamburger */}
//         <div className='md:hidden flex mr-4 justify-end items-center' onClick={handleClick}>
//             {!nav ? <MenuIcon className='w-5' /> : <XIcon className='w-5' />} 
//         </div>

//       </div>

//         {/* Menu mobile */}
//       <ul className={!nav ? 'hidden' : 'absolute lien bg-zinc-200 w-full px-10 top-0 left-0 h-screen flex flex-col justify-center items-center'}>
//           <button className='border-b-2 bg-transparent border-zinc-300 w-full'><Link onClick={handleClick} to="home" smooth={true} duration={500}>Présentation</Link></button>          <button className='border-b-2 bg-transparent border-zinc-300 w-full'><Link onClick={handleClose} to="application" smooth={true} offset={-100} duration={500}>Application B-FLOW</Link></button>
//           <button className='border-b-2 bg-transparent border-zinc-300 w-full'><Link onClick={handleClick} to="contact" smooth={true} offset={-50} duration={500}>Contact</Link></button>
//       </ul>

//     </div>
//   );
// };

// export default Navbar;

import React from 'react';
import styled from 'styled-components';
import Burger from './Burger';
import Logo from '../../assets/logov2.png';


const Nav = styled.nav`
  width: 100%;
  height: 55px;
  padding: 0 0px;
  display: flex;
  justify-content: space-between;

  .logo_navbar {
    padding: 0px 0;
  
  }

  @media (max-width: 768px) {
    .logo_navbar {
      margin-left: auto;
      margin-right: auto;
      horizontal-align:middle;
    }
    
    .navbar_items {
      display:block ;
      margin-left: auto;
      margin-right: auto;
    }


    

  }

`

const Navbar = () => {
  return (
    <Nav className="navbar_items items-center max-w-[1240px] m-auto my-8">
      <div className="logo_navbar">
        <img src={Logo} alt="Logo" className="w-44" />
      </div>
      <Burger />
    </Nav>
  )
}

export default Navbar