import React from 'react';
import './FooterStyles.css';
import Logo from '../../assets/logowhite.png';

import { FaFacebook,FaLinkedin, FaSearchLocation, FaPhone, FaMailBulk } from 'react-icons/fa';
// import { MdAlternateEmail, MdPhone } from 'react-icons/md'



const Footer = () => {
  return (

        <footer class="p-4  shadow md:px-6 md:py-8 bg-gray-800 dark:bg-gray-800">
        <div class="sm:flex sm:items-center sm:justify-center space-x-8 ">
            <a href="https://b-flow.fr" class="flex items-center mb-4 sm:mb-0">
                <img src={Logo} alt="Logo" class="mr-3 h-44"/>
            </a>
            <ul class="info flex flex-col flex-wrap mb-6 text-sm sm:w-[300px] text-white sm:mb-0 dark:text-white">
                <li>
                  <h4><FaSearchLocation size={20} style={{ color: '#ffffff', marginRight: '2rem',  }} />17 square de la chasse aux papillons, 91080 Evry-Courcouronnes</h4>
                </li>
                <li>
                <h4><FaPhone size={20} style={{ color: '#ffffff', marginRight: '2rem' }} />+33 6 46 53 19 25</h4>                </li>
                <li>
                <h4><FaMailBulk size={20} style={{ color: '#ffffff', marginRight: '2rem' }} />contact@b-flow.fr</h4>
                </li>
            </ul>
        </div>
        <hr class="my-6 border-gray-700 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className='social sm:flex sm:flex-wrap sm:justify-center sm:items-center space-x-4  pt-4 text-2xl fill-white'>
        <span class="rights block text-sm text-white sm:text-center dark:text-white">© 2022 <a href="https://b-flow.fr" class="hover:underline">B-FLOW™</a>. Tous droits réservés.</span>
        <FaFacebook size={24} onClick={() => {window.open("https://www.facebook.com/BFlowFrance", "_blank");}} style={{ color: '#ffffff' }} />
        <FaLinkedin size={24} onClick={() => {window.open("https://www.linkedin.com/company/b-flowfr/", "_blank");}} style={{ color: '#ffffff' }} />
        </div>
        </footer>
        
  )
}

export default Footer;