import React from 'react'
import Typed from 'react-typed';
import './HeroStyles.css';
import {
    CloudUploadIcon,
    DatabaseIcon,
    PaperAirplaneIcon,
    LockClosedIcon,
} from '@heroicons/react/solid'

import bgImg from '../../assets/cyber-bg.png'

const Hero = () => {
  return (
    <div name='home' className='w-full bg-white flex flex-col justify-between relative px-4 py-16'>
        <div className='grid md:grid-cols-2 max-w-[1240px] space-y-8 m-auto'>
            <div className='flex flex-col justify-center md:items-start w-full px-2 py-8'>
                <h1 className='py-3 text-5xl md:text-7xl font-bold'>Mettez de la rentabilité dans vos chantiers !</h1>
                <div className='flex justify-center items-center'>
                    <p className='md:text-2xl sm:text-2xl text-xl py-4'>
                        Un journal de chantier
                    </p>
                    <Typed
                    className='multitext md:text-2xl sm:text-2xl text-xl pl-1 font-bold text-teal-500'
                        strings={['accessible', 'efficace', 'simple', 'digital']}
                        typeSpeed={100}
                        backSpeed={100}
                        loop
                    />
                </div>
            </div>
            <div>
                <img className='w-full' src={bgImg} alt="/" />
            </div>
            <div className='flex flex-col justify-center py-4 px-2  bottom-[5%]
            mx-1  transform bg-white
            border font-bold border-white rounded-xl text-center shadow-lg backdrop-blur-0 text-lg'>
                <p>Nos services</p>
                <div className='flex justify-between flex-wrap px-8'>
                    <p className='flex px-4 py-2 text-slate-500 text-lg'><CloudUploadIcon className='h-6 text-teal-400' /> Productivité</p>
                    <p className='flex px-4 py-2 text-slate-500 text-lg'><DatabaseIcon className='h-6 text-teal-400' /> Économie</p>
                    <p className='flex px-4 py-2 text-slate-500 text-lg'><LockClosedIcon className='h-6 text-teal-400' /> Sécurité</p>
                    <p className='flex px-4 py-2 text-slate-500 text-lg'><PaperAirplaneIcon className='h-6 text-teal-400' /> Simplicité</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Hero