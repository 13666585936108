import React from 'react';
import PC from "../../assets/bureau.png"


const About = () => {
  return (
    <div name='about' className='w-full my-20'>
        <div className='max-w-[1240px] mx-auto px-4'>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <div className='flex flex-col justify-center space-y-8'>
          <h1 className='md:text-5xl sm:text-3xl text-2xl font-bold py-2'>Adapté à tout type de chantier</h1>
          <div className='flex flex-col justify-center space-y-2'>
          <p className='md:text-2xl font-semibold text-xl'>Développement sur mesure</p>
          <p className='md:text-1xl text-xl'>
          Fini ces logiciels trop rigides qui ne fonctionnent pas avec vos activités. B-FLOW travaille avec vous pour produire les outils qui répondent au mieux à vos attentes.
          </p>
          </div>
          <div className='flex flex-col justify-center space-y-2'>
          <p className='md:text-2xl font-semibold text-xl'>Support dédié</p>
          <p className='description md:text-1xl text-xl'>
          Nous mettons en place un support réactif qui saura répondre à vos attentes.
          </p>
          </div>     
        </div>
        <img className='w-[500px] mx-auto my-4' src={PC} alt="PC" />
      </div>
        </div>
    </div>
  )
}

export default About