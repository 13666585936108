import React from 'react';
import { Collapse, Text, Button } from "@nextui-org/react";
import PC from "../../assets/pc.png";
import './RDVStyles.css'

const RDV = () => {
  return (
    <div name='rdv' className='w-full my-32 relative z-0'>
    <div className='w-full bg-white py-16 px-4 '>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 '>
        <img className='w-[500px] mx-auto my-4' src={PC} alt="PC" />
        <div className='flex flex-col justify-center space-y-3'>
          <h1 className='md:text-5xl sm:text-3xl text-2xl font-bold py-2'>Un historique de chantier 360°</h1>
          <p className='md:text-1xl text-xl'>
          B-FLOW est l'outil parfait pour générer vos documents qualité et alimenter vos études de prix et vos réclamations avec des données puisées à la source.
          </p>
          <Collapse.Group className=''>
      <Collapse title="Historique" className="font-semibold text-xl">
        <Text className="font-normal text-lg">
        Conservez un historique complet de vos travaux sous plusieurs formats : son, image et texte. L'ensemble est stocké dans une base de donnée consultable et organisée permettant la génération de rapports automatiques.
        </Text>
      </Collapse>
      <Collapse title="Enregistrements sonores" className="font-semibold text-xl">
        <Text className="font-normal text-lg">
        La transmission orale des informations est omniprésente dans le milieu du BTP, c'est pourquoi nous vous proposons des enregistrements sonores qui sont ensuite retranscrits afin de maintenir ce lien oral et s'assurer que les informations cruciales soient bien identifiées.
        </Text>
      </Collapse>
      <Collapse title="Photos et vidéos" className="font-semibold text-xl">
        <Text className="font-normal text-lg">
        Complétez vos journaux de chantier par des photos ou vidéos courtes des situations de chantier.
        </Text>
      </Collapse>
    </Collapse.Group>
         
        </div>
      </div>
    
      <div className="max-w-[300px] mx-auto grid px-5 " style={{marginTop: 50}}>
      <Button size="xl"  color="success" place-content-center style={{backgroundColor: "#06B7DB", }}onClick={() => {window.open("https://calendly.com/bflow/rendez-vous-avec-equipe-bflow?month=2022-04", "_blank");}} auto shadow>
        Prenez rendez-vous
      </Button>
          </div>  
    </div>
    </div>
  );
};

export default RDV;
