import React from 'react';
import IMTStarter from '../../assets/LogoIMT_gris.png';



const Partenaire = () => {
  return (
    <div name='partenaire' className='w-full my-20 bg-slate-50'>
        <div className='max-w-[1240px] mx-auto px-4'>
            {/* <h1 className='md:text-3xl sm:text-3xl text-2xl font-bold py-2 flex justify-center'>Ils nous font confiance</h1> */}
            <div className='max-w-[1240px] mx-auto flex justify-center py-8'>
                <img alt="IMTStarter" className="flex justify-center" width="200" src={IMTStarter}></img>
            </div>
        </div>
    </div>
  )
}

export default Partenaire