import About from './components/About/About';
import Application from './components/Application/Application';
import Footer from './components/Footer/Footer';
import Hero from './components/Hero/Hero';
import Navbar from './components/Navbar/Navbar';
import RDV from './components/RDV/RDV'; 
import Partenaire from './components/Partenaire/Partenaire';
import { React, useState, useEffect } from 'react';
import { CircleLoader } from 'react-spinners';
import './App.css';



function App() {
  const [loading, setLoading] = useState(false);

  useEffect(()=> {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 3400)
  }, [])

  return (
    <div>
    {
      loading ?
      <div className="CircleLoader">
      <CircleLoader  size={30} color={"#06DBEB"} loading={loading} />
      </div>

      :
      <div >

      <Navbar />
      <Hero />
      <Partenaire />
      <About />
      <Application />
      <RDV />
      <Footer />
    </div>
    }
    </div>
    )

}

export default App;