import React from 'react';
import './ApplicationStyles.css'
import {ClockIcon, ClipboardCopyIcon, MicrophoneIcon} from '@heroicons/react/solid'

import supportImg from '../../assets/mockup.png'

const Application = () => {
  return (
    
  <div name='application' className='w-full my-5 relative z-0 '>
    <div style={{ backgroundImage: `url(${supportImg})` }} className="bg-cover relative mix-blend-overlay object-cover bg-black/20">    
        <div className='w-full bg-slate-500/80 bg-scroll'>
            <div className='max-w-[1240px] mx-auto text-white bg-scroll relative'>
                <div className='px-4 py-12'>
                    <h3 className='text-5xl font-bold py-6 text-center '><p className="bflow text-transparent bg-clip-text bg-gradient-to-r from-teal-200 to-cyan-500">L'application B-FLOW</p> <p>votre compagnon de suivi de chantier</p></h3>
                    <p className='py-4 text-3xl text-center text-sky-50'>Remontez les données de vos chantiers comme un simple coup de fil !</p>
                    <div className='grid grid-cols-1 lg:grid-cols-3  gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black'>
                        <div className='backdrop-blur-lg bg-white/30 border rounded-xl shadow-2xl '>
                            <div className='p-8'>
                                <MicrophoneIcon className='w-16 p-4 bg-emerald-300 text-white rounded-lg mt-[-4rem] ' />
                                <h3 className='font-bold text-2xl my-6 text-white'>Enregistrements vocaux retranscrits</h3>
                                <p className='text-gray-100 text-xl'>Dictez oralement et conservez une trace écrite de vos enregistrements grâce à la transformation voix vers texte</p>
                            </div>
                        </div>
                    <div className='backdrop-blur-lg bg-white/30 border rounded-xl shadow-2xl'>
                        <div className='p-8'>
                            <ClipboardCopyIcon className='w-16 p-4 bg-emerald-300 text-white rounded-lg mt-[-4rem]' />
                            <h3 className='font-bold text-2xl my-6 text-white'>Rapports journaliers automatiques</h3>
                            <p className='text-gray-100 text-xl'>Générez vos rapports journaliers aisément grâce à vos données de chantier</p>
                        </div>
                    </div>
                        <div className='backdrop-blur-lg bg-white/30 border rounded-xl shadow-2xl'>
                            <div className='p-8'>
                                <ClockIcon className='w-16 p-4 bg-emerald-300 text-white rounded-lg mt-[-4rem]' />
                                <h3 className='font-bold text-2xl my-6 text-white'>Enrichissez votre historique</h3>
                                <p className='text-gray-100 text-xl'>En capturant des photos et des vidéos liées à vos chantiers</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  );
};

export default Application;