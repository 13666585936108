import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import './NavbarStyles.css';
import { Button } from '@nextui-org/react';

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  position: relative;

  li {
    padding: 18px 10px;
  }

  @media (max-width: 768px) {
    visibility; hidden;
    flex-flow: column nowrap;
    background-color: black;
    backdrop-filter: blur(5px);
    position: fixed;
    transform: ${({ open }) => open ? 'translateY(00%)' : 'translateY(-100%)'};
    top: 0;
    right: 0;
    height: 20vh;
    padding: 20px;
    width: 100% ;
    transition: transform 0.3s ease-in-out;

   button {
      padding: 10px;
      margin-top: -10px;
      color: white;
    }
  }
`;


const RightNav = ({ open }) => {
  return (
    <Ul open={open} className="lien backdrop-blur-md  items-center justify-end z-10 pr-px">
          <button className='border-none bg-transparent text-black px-1 mr-4 text-lg hover:text-teal-700'>
          <Link to="home" smooth={true} duration={500} >Présentation</Link>
          </button>
          <button className='border-none bg-transparent text-black px-1 mr-4  text-lg hover:text-teal-700'>
          <Link to="application" smooth={true} offset={-100} duration={800}>Application B-FLOW</Link>
          </button>
          <button className='border-none bg-transparent text-black px-1 mr-4 text-lg hover:text-teal-700'>
          <Link to="contact" smooth={true} offset={-50} duration={1000}>Contact</Link>
          </button>
          <Button  size="lg" color="success" className="px-1" style={{backgroundColor: '#06B7DB'}} onClick={() => {window.open("https://calendly.com/bflow/rendez-vous-avec-equipe-bflow?month=2022-04", "_blank");}} auto shadow>
        Prenez rendez-vous
      </Button>
    </Ul>
  )
}

export default RightNav